import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Product.style';
import { ProductContext } from '../Context';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { updateModalsProduct } from '../Action';
import { Form, Input, Select } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { EditProductApi, getDetailProduct, getPolicySelect } from '../Product.api';
import { notificationController } from '@app/controllers/notificationController';
import { DataResponseApiProductList, DataResponseDetailProduct, DataResponsePolicySelectApi } from '../interface';
import { formatNumberWithCommas } from '@app/utils/utils';
import { DataResponseApiPolicyList } from '../../Policy/interface';

type Props = {
  saveDataRecord: DataResponseApiProductList | undefined;
  dataProduct: DataResponseApiProductList[] | undefined;
  dataListPolicy: DataResponseApiPolicyList | undefined;
};

export default function ModalEditProduct(props: Props) {
  const { stateProduct, dispatchProduct } = useContext(ProductContext);
  const [dataDetailProduct, setdataDetailProduct] = useState<DataResponseDetailProduct>();
  const [savePolicyCode, setsavePolicyCode] = useState<string | undefined>(props?.saveDataRecord && props?.saveDataRecord?.policy?.code);
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [dataPolicySelect, setdataPolicySelect] = useState<DataResponsePolicySelectApi>();
  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  function handleCancel() {
    dispatchProduct(updateModalsProduct({ ...stateProduct?.modalsProduct, modalEdit: false }));
    // form.setFieldsValue({
    //   name: '',
    //   description: '',
    //   policyCode: undefined,
    // });
    // setdataPolicySelect(undefined);
  }

  const onFinish = async (values: any) => {
    setloading(true);
    if (values && savePolicyCode && props?.dataProduct && props?.saveDataRecord?.name) {
      EditProductApi(props?.saveDataRecord?.name, {
        description: values?.description && values?.description,
        policyCode: values?.policyCode && values?.policyCode,
      })
        .then((res) => {
          dispatchProduct(updateModalsProduct({ ...stateProduct?.modalsProduct, modalEdit: false }));

          if (res?.error) {
            setloading(false);
            setdataPolicySelect(undefined);
          } else {
            setloading(false);
            notificationController.success({ message: `Sửa thành công ` });
            setdataPolicySelect(undefined);
          }
        })
        .catch(() => {
          notificationController.error({ message: `Something went wrong  Api` });
          setloading(false);
          setdataPolicySelect(undefined);

          dispatchProduct(updateModalsProduct({ ...stateProduct?.modalsProduct, modalEdit: false }));
        });
    }
  };

  function onChangeSelect(e: any) {
    if (e) {
      setsavePolicyCode(e);
    }
  }

  const callApiDetailProduct = async () => {
    if (props?.saveDataRecord?.name) {
      await getDetailProduct(props?.saveDataRecord?.name)
        .then((res) => {
          setdataDetailProduct(res);
        })
        .catch((err) => {
          console.log(err);
          setdataDetailProduct(undefined);
        });
    }
  };

  useEffect(() => {
    if (savePolicyCode) {
      getPolicySelect(savePolicyCode)
        .then((res: DataResponsePolicySelectApi) => {
          res && setdataPolicySelect(res);
        })
        .catch((err) => {
          console.log(err);
          setdataPolicySelect(undefined);
        });
    }
  }, [savePolicyCode, props?.saveDataRecord]);

  useEffect(() => {
    callApiDetailProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.saveDataRecord?.name]);

  useEffect(() => {
    if (props?.dataProduct && props?.saveDataRecord && dataDetailProduct) {
      form.setFieldsValue({
        name: dataDetailProduct?.name,
        policyCode: props?.saveDataRecord?.policy?.code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.dataProduct, props?.saveDataRecord, dataDetailProduct]);

  return (
    <S.ModalEditProduct title={`${props?.saveDataRecord?.name}`} open={stateProduct?.modalsProduct?.modalEdit} footer={null} onCancel={handleCancel}>
      <Form form={form} name="basic" onFinish={onFinish} validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <div>
            <div className="content-header">
              <div className="item-form">
                <div className="label">Tên sản phẩm</div>
                <Form.Item name={'name'} label="Tên sản phẩm" rules={[{ required: true, type: 'string' }]}>
                  <Input type="string" size={'small'} className={'style-input-create'} />
                </Form.Item>
              </div>
              <div className="item-form">
                <div className="label"> Chính sách mặc định</div>
                <Form.Item name={'policyCode'} rules={[{ required: true, message: 'Chọn chính sách mặc địch' }]}>
                  <Select onChange={onChangeSelect} placeholder={'Chọn chính sách mặc địch'} size={'small'} dropdownMatchSelectWidth={200}>
                    {props?.dataListPolicy?.data &&
                      props?.dataListPolicy?.data?.map((item, index) => (
                        <Select.Option key={index} value={`${item?.code}`}>
                          {item?.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="content-body">
              <h2 className="title-modal">Thông tin chính sách</h2>
              <div className="box-col">
                <div className="item-form">
                  <div className="item-line-product">
                    <div className="label">Tỉ lệ cho vay trên đơn hàng </div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.maximumLoanRate + ' %' : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Số tiền cho vay tối đa/khoản vay </div>
                    <div className="value">{dataPolicySelect ? formatNumberWithCommas(dataPolicySelect?.maximumLoanAmount ? dataPolicySelect?.maximumLoanAmount : 0) : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Số tiền duyệt tự động(≤) </div>
                    <div className="value">{dataPolicySelect ? formatNumberWithCommas(dataPolicySelect?.loanAmountAutoApprove ? dataPolicySelect?.loanAmountAutoApprove : 0) : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Điểm tín dụng tối thiểu của đơn hàng </div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.creditScore : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Lãi/năm </div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.interestRate + ' %' : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Phí </div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.feeRate + ' %' : '-'}</div>
                  </div>
                </div>
                <div className="item-form">
                  <div className="item-line-product">
                    <div className="label">Ngày ân hạn </div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.gracePeriod + ' Ngày' : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Số ngày/ kỳ</div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.dayPerPeriod + ' Ngày' : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Phí quá hạn kỳ 1</div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.lateFeeFirstTerm + ' %' : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Phí tăng sau mỗi kỳ</div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.increasingFeePerPeriod + ' %' : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Số ngày bị thu hồi</div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.dayRecall + ' Ngày' : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Phí quá hạn cố định</div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.delinquencyFee + ' %' : '-'}</div>
                  </div>
                </div>
              </div>
            </div>
            <FooterModal handleClickCancel={handleCancel} handleLoading={loading} type="submit" padding="24px 0px 0px 0px" LabelSave="Lưu thay đổi" labelCancel="Huỷ"></FooterModal>
          </div>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalEditProduct>
  );
}
