import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Loan.styles';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import * as GS from '@app/styles/GlobalStyle';
import { Form } from 'antd';
import { LoanListContext } from '../Context';
import { updateStateModalDisbursement } from '../Action';
import { formatNumberWithCommas } from '@app/utils/utils';
import { DataTablePolicy } from '../../Policy/interface';
import { getDetailPolicy } from '../Loan.api';

type Props = {
  policyCode: string;
};
export default function ModalDetailPolicy(props: Props) {
  const [form] = Form.useForm();
  const { stateLoanList, dispatchLoanList } = useContext(LoanListContext);
  const [dataPolicy, setdataPolicy] = useState<DataTablePolicy>();

  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  function handleCancel() {
    dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList?.stateModal, openModalDetailPolicy: false }));
  }

  function callApiGetDetailPolicy() {
    if (props?.policyCode) {
      getDetailPolicy(props?.policyCode)
        .then((res) => {
          res && setdataPolicy(res);
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }
  useEffect(() => {
    callApiGetDetailPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.ModalDetailPolicy
      title={'Chi tiết chính sách'}
      open={stateLoanList?.stateModal?.openModalDetailPolicy && stateLoanList?.stateModal?.openModalDetailPolicy}
      footer={null}
      onCancel={handleCancel}
    >
      <Form form={form} name="basic" validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <div>
            <div className="content-header">
              <div className="item-form">
                <div className="label">Tên chính sách</div>
                <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                  {dataPolicy?.name ? dataPolicy?.name : '-'}
                </div>
              </div>
            </div>
            <h2 className="title-modal">Cài đặt cấu hình chung</h2>
            <div className="content-body">
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỉ lệ cho vay trên đơn hàng</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {dataPolicy?.maximumLoanRate ? dataPolicy?.maximumLoanRate + ' %' : 0}
                  </div>
                </div>
                <div className="item-form">
                  <div className="label">Ngày ân hạn</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {dataPolicy?.gracePeriod ? dataPolicy?.gracePeriod + ' Ngày' : 0}
                  </div>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Số tiền cho vay tối đa trên khoản vay</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {dataPolicy?.maximumLoanAmount ? formatNumberWithCommas(dataPolicy?.maximumLoanAmount) + ' đ' : 0}
                  </div>
                </div>
                <div className="item-form">
                  <div className="label">Số ngày/ kỳ</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {dataPolicy?.dayPerPeriod ? dataPolicy?.dayPerPeriod + ' Ngày' : 0}
                  </div>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Số tiền duyệt tự động</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {dataPolicy?.loanAmountAutoApprove ? formatNumberWithCommas(dataPolicy?.loanAmountAutoApprove) + ' đ' : 0}
                  </div>
                </div>
                <div className="item-form">
                  <div className="label">Phí quá hạn kỳ 1</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {dataPolicy?.lateFeeFirstTerm ? dataPolicy?.lateFeeFirstTerm + ' %' : 0}
                  </div>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Điểm tín dụng tối thiểu của đơn hàng</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {dataPolicy?.creditScore ? dataPolicy?.creditScore + ' điểm' : 0}
                  </div>
                </div>
                <div className="item-form">
                  <div className="label">Phí tăng sau mỗi kỳ</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {dataPolicy?.increasingFeePerPeriod ? formatNumberWithCommas(dataPolicy?.increasingFeePerPeriod) + ' %' : 0}
                  </div>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Lãi/ năm</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {dataPolicy?.interestRate ? dataPolicy?.interestRate + ' %' : 0}
                  </div>
                </div>
                <div className="item-form">
                  <div className="label">Số ngày bị thu hồi</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {dataPolicy?.dayRecall ? dataPolicy?.dayRecall + ' Ngày' : 0}
                  </div>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Phí</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {dataPolicy?.feeRate ? dataPolicy?.feeRate + ' %' : 0}
                  </div>
                </div>
                <div className="item-form">
                  <div className="label">Phí quá hạn cố định</div>
                  <div className="item-fil-detail" style={{ marginBottom: '20px' }}>
                    {dataPolicy?.delinquencyFee ? dataPolicy?.delinquencyFee + ' %' : 0}
                  </div>
                </div>
              </div>
            </div>

            <FooterModal handleClickCancel={handleCancel} type={undefined} padding="24px 0px 0px 0px" labelCancel="Đóng"></FooterModal>
          </div>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalDetailPolicy>
  );
}
