import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Partner.style';
import { PartnerContext } from '../Context';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { updateModalsPartner } from '../Action';
import { Form, Select } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { notificationController } from '@app/controllers/notificationController';
import { DataResponseApiPartnerList } from '../interface';
import { formatNumberWithCommas } from '@app/utils/utils';
import { DataResponseApiPolicyList } from '../../Policy/interface';
import { getPolicySelect } from '../../Product/Product.api';
import { getPolicyApi } from '../../Policy/Policy.api';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { PolicyContext } from '../../Policy/Context';
import { DataResponsePolicySelectApi } from '../../Product/interface';
import { useParams } from 'react-router-dom';
import { EditPolicyDefaultPartnerApi } from '../Partner.api';

type Props = {
  saveDataRecord?: DataResponseApiPartnerList | undefined;
  dataPartner?: DataResponseApiPartnerList[] | undefined;
  dataListPolicy?: DataResponseApiPolicyList | undefined;
  codeDefault: string | undefined;
};

export default function ModalEditPolicyDefault(props: Props) {
  const { statePartner, dispatchPartner } = useContext(PartnerContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkLoading, setcheckLoading] = useState(false);

  const [savePolicyCode, setsavePolicyCode] = useState<string | undefined>(props?.codeDefault && props?.codeDefault);
  const [form] = Form.useForm();
  const [dataListPolicy, setdataListPolicy] = useState<DataResponseApiPolicyList>();
  const { statePolicy } = useContext(PolicyContext);
  const [loading, setloading] = useState(false);
  const { uuid } = useParams();
  const { productName } = useParams();

  const [dataPolicySelect, setdataPolicySelect] = useState<any>();
  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  const callApiListPolicy = async () => {
    setcheckLoading(true);

    await getPolicyApi(queryStringParams(statePolicy?.filtersPolicy))
      .then((res) => {
        if (res?.error) {
          setdataListPolicy(undefined);
          setcheckLoading(false);
        } else {
        }
        setdataListPolicy(res);
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setdataListPolicy(undefined);
        setcheckLoading(false);
      });
  };

  function handleCancel() {
    dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, modalEditPolicyDefault: false }));

    form.setFieldsValue({
      code: props?.codeDefault,
    });
    // setdataPolicySelect(undefined);
  }

  const onFinish = async (values: any) => {
    setloading(true);
    if (values && savePolicyCode) {
      EditPolicyDefaultPartnerApi(uuid, productName, {
        code: savePolicyCode,
      })
        .then((res) => {
          dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, modalEditPolicyDefault: false }));

          if (res?.error) {
            setloading(false);
            setdataPolicySelect(undefined);
          } else {
            setloading(false);
            notificationController.success({ message: `Sửa thành công` });
          }
        })
        .catch(() => {
          notificationController.error({ message: `Something went wrong  Api` });
          setloading(false);
          setdataPolicySelect(undefined);

          dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, modalEditPolicyDefault: false }));
        });
    }
  };

  function onChangeSelect(e: any) {
    if (e) {
      setsavePolicyCode(e);
    }
  }

  useEffect(() => {
    callApiListPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statePolicy?.filtersPolicy]);

  useEffect(() => {
    if (props?.codeDefault) {
      getPolicySelect(props?.codeDefault)
        .then((res: DataResponsePolicySelectApi) => {
          res && setdataPolicySelect(res);
        })
        .catch((err) => {
          console.log(err);
          setdataPolicySelect(undefined);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.codeDefault]);

  useEffect(() => {
    if (savePolicyCode) {
      getPolicySelect(savePolicyCode ? savePolicyCode : props?.codeDefault)
        .then((res: DataResponsePolicySelectApi) => {
          res && setdataPolicySelect(res);
        })
        .catch((err) => {
          console.log(err);
          setdataPolicySelect(undefined);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savePolicyCode]);

  useEffect(() => {
    if (props?.codeDefault) {
      form.setFieldsValue({
        code: props?.codeDefault,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.codeDefault]);

  return (
    <S.ModalEditPartner title={`Chính sách mặc định`} open={statePartner?.modalsPartner?.modalEditPolicyDefault} footer={null} onCancel={handleCancel}>
      <Form form={form} name="basic" onFinish={onFinish} validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <div>
            <div className="content-header">
              <div className="item-form">
                <div className="label"> Chính sách mặc định</div>
                <Form.Item name={'code'} rules={[{ required: true, message: 'Chọn chính sách mặc địch' }]}>
                  <Select onChange={onChangeSelect} placeholder={'Chọn chính sách mặc địch'} size={'small'} dropdownMatchSelectWidth={200}>
                    {dataListPolicy?.data &&
                      dataListPolicy?.data?.map((item, index) => (
                        <Select.Option key={index} value={`${item?.code}`}>
                          {item?.code}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="content-body-default-policy">
              <h2 className="title-modal">Thông tin chính sách</h2>
              <div className="box-col">
                <div className="item-form">
                  <div className="item-line-product">
                    <div className="label">Tỉ lệ cho vay trên đơn hàng </div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.maximumLoanRate + ' %' : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Số tiền cho vay tối đa/khoản vay </div>
                    <div className="value">{dataPolicySelect ? formatNumberWithCommas(dataPolicySelect?.maximumLoanAmount ? dataPolicySelect?.maximumLoanAmount : 0) : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Số tiền duyệt tự động(≤) </div>
                    <div className="value">{dataPolicySelect ? formatNumberWithCommas(dataPolicySelect?.loanAmountAutoApprove ? dataPolicySelect?.loanAmountAutoApprove : 0) : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Điểm tín dụng tối thiểu của đơn hàng </div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.creditScore : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Lãi/năm </div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.interestRate + ' %' : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Phí </div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.feeRate + ' %' : '-'}</div>
                  </div>
                </div>
                <div className="item-form">
                  <div className="item-line-product">
                    <div className="label">Ngày ân hạn </div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.gracePeriod + ' Ngày' : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Số ngày/ kỳ</div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.dayPerPeriod + ' Ngày' : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Phí quá hạn kỳ 1</div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.lateFeeFirstTerm + ' %' : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Phí tăng sau mỗi kỳ</div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.increasingFeePerPeriod + ' %' : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Số ngày bị thu hồi</div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.dayRecall + ' Ngày' : '-'}</div>
                  </div>
                  <div className="item-line-product">
                    <div className="label">Phí quá hạn cố định</div>
                    <div className="value">{dataPolicySelect ? dataPolicySelect?.delinquencyFee + ' %' : '-'}</div>
                  </div>
                </div>
              </div>
            </div>
            <FooterModal handleClickCancel={handleCancel} handleLoading={loading} type="submit" padding="24px 0px 0px 0px" LabelSave="Lưu thay đổi" labelCancel="Huỷ"></FooterModal>
          </div>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalEditPartner>
  );
}
