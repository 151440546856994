import { useContext, useEffect, useState } from 'react';
import * as S from '../Capital.styles';
import * as GS from '@app/styles/GlobalStyle';
import { ReactComponent as IconUploadFile } from '@app/assets/icons/icon-uploadfile.svg';

import { CapitalContext } from '../Context';
import { updateModals } from '../Action';
import { Checkbox, Col, Form, Input, message, Row, Upload, UploadProps, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { UploadImgApi } from '@app/api/UploadFIle.api';
import { createCapitalApi } from '../Capital.api';
import { notificationController } from '@app/controllers/notificationController';
import _ from 'lodash';
import { getProductApi } from '../../Product/Product.api';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { queryStringParams } from '@app/controllers/queryStringParams';

type Props = {
  callApiListCapital: () => void;
};
export default function ModalCreateCapital(props: Props) {
  const [checkboxName, setcheckboxName] = useState(false);
  const [saveStringImg, setsaveStringImg] = useState<string | null>(null);
  const { stateCapital, dispatchCapital } = useContext(CapitalContext);
  const [checkBtnLoading, setcheckBtnLoading] = useState(false);
  const [optionCheckbox, setoptionCheckbox] = useState<any>();
  const [optionCheckboxSave, setoptionCheckboxSave] = useState<any>();

  const [form] = Form.useForm();
  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  const { Dragger } = Upload;

  const propsUpdate: UploadProps = {
    name: 'file',
    multiple: true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeUpload(file, FileList) {
      return false;
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        const formData = new FormData();

        formData.append('file', info.file);

        UploadImgApi(
          formData,
          queryStringParams({
            packageType: 'FUND',
            partnerUuid: undefined,
          }),
        )
          .then((res) => {
            if (!res.error) {
              message.success(`${info.file.name} file uploaded successfully.`);
              if (res) {
                setsaveStringImg(res?.key);
              }
            } else {
              // message.error(`${info.file.name} file  deleted `);
              setsaveStringImg('');
            }
          })
          .catch((err) => {
            message.error(`${err} Err 404`);
          });
      }
    },
  };

  function handleCancelModalCreate() {
    dispatchCapital(updateModals({ ...stateCapital.modals, modalCreate: false }));
    form.setFieldsValue({
      name: '',
      topUp: '',
      representName: '',
      loanProduct: '',
      contract: '',
      checkBoxProduct: '',
      type: '',
    });
    setcheckboxName(false);
    setoptionCheckboxSave([]);
  }

  const onChangeName = (e: CheckboxChangeEvent) => {
    setcheckboxName(e.target.checked);
  };

  const onFinish = async (values: any) => {
    if (values) {
      setcheckBtnLoading(true);
      await createCapitalApi({
        name: values && values?.name,
        topUp: values && values?.topUp,
        representName: values ? values?.representName : null,
        loanProduct: optionCheckboxSave.join(),
        contract: saveStringImg ? saveStringImg : null,
        type: values?.type,
      })
        .then((res) => {
          if (res?.error) {
            dispatchCapital(updateModals({ ...stateCapital.modals, modalCreate: false }));
            setcheckBtnLoading(false);
            form.setFieldsValue({
              name: '',
              topUp: '',
              representName: '',
              loanProduct: '',
              contract: '',
              checkBoxProduct: '',
              type: '',
            });
            setcheckboxName(false);
            setoptionCheckboxSave([]);
          } else {
            dispatchCapital(updateModals({ ...stateCapital.modals, modalCreate: false }));
            setcheckBtnLoading(false);
            form.setFieldsValue({
              name: '',
              topUp: '',
              representName: '',
              loanProduct: '',
              contract: '',
              checkBoxProduct: '',
              type: '',
            });
            props.callApiListCapital();

            setcheckboxName(false);
            setoptionCheckboxSave([]);
            notificationController.success({ message: `Tạo thành công` });
          }
        })
        .catch((err) => {
          notificationController.error({ message: _.get(err, `Create fail`, `${err} err API`) });
          dispatchCapital(updateModals({ ...stateCapital.modals, modalCreate: false }));
          setcheckBtnLoading(false);
        });
    }
  };

  const callApiListProduct = async () => {
    const arr: any = [];
    await getProductApi()
      .then((res) => {
        if (res?.error) {
        } else {
          res &&
            res?.map((item: any) => {
              arr.push({ label: item?.name, value: item?.name });
            });
          setoptionCheckbox(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = async (checkedValues: CheckboxValueType[]) => {
    await setoptionCheckboxSave(checkedValues);
  };

  useEffect(() => {
    callApiListProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.ModalCreate title="Thêm nguồn vốn" open={stateCapital?.modals?.modalCreate} onCancel={handleCancelModalCreate} footer={null}>
      <GS.WrapperOverrideInput style={{ width: '100%', display: 'flex' }}>
        <Form form={form} name="basic" onFinish={onFinish} validateMessages={validateMessages}>
          <div className="content">
            <div className="box-content-body-take-border-bottom">
              <div className="box-content-body">
                <Col className="col-1">
                  <Row className="label">Tên nguồn vốn</Row>
                  <Form.Item name={'name'} label="Tên nguồn vốn" rules={[{ required: true, type: 'string' }]}>
                    <Input size={'small'} className={'style-input-create'} placeholder={'Nhập tên nguồn vốn'} />
                  </Form.Item>
                  <Row className="row-checkbox">
                    <Checkbox checked={checkboxName} onChange={onChangeName}>
                      <span className="title-checkbox">Tên đại diện</span>
                    </Checkbox>
                  </Row>

                  {checkboxName ? (
                    <>
                      {' '}
                      <Row className="label">Tên đại diện</Row>
                      <Form.Item name={'representName'}>
                        <Input size={'small'} className={'style-input-create'} placeholder={'Nhập tên đại diện'} />
                      </Form.Item>
                    </>
                  ) : (
                    ''
                  )}

                  <Row className="label">Tổng kí quỹ</Row>
                  <Form.Item name={'topUp'} label="Tổng kí quỹ" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} placeholder={'Nhập tổng giá trị'} />
                  </Form.Item>
                  <Row className="label">Loại nguồn vốn</Row>
                  <Form.Item name={'type'}>
                    <Select placeholder={'Chọn loại nguồn vốn'} size={'small'}>
                      <Select.Option value={'ESCROW_CAPITAL'}>Kí quỹ</Select.Option>
                      <Select.Option value={'NON_ESCROW_CAPITAL'}>Không kí quỹ</Select.Option>
                    </Select>
                  </Form.Item>
                  <Row className="label margin-bottom-20">Loại khoản vay</Row>

                  <Row className="row-checkbox-type">
                    <Form.Item name="checkBoxProduct" valuePropName="checked" label="loại khoản vay" rules={[{ required: true }]}>
                      <Checkbox.Group value={optionCheckboxSave} options={optionCheckbox && optionCheckbox} onChange={onChange} />
                    </Form.Item>
                  </Row>
                  {/* <div className="err-text">{checkValidate ? 'Hãy chọn loại khoản vay' : ''}</div> */}
                </Col>
                <Col className="col-2">
                  <Row className="label">
                    <span className="icon-special">*</span> Chứng từ / hợp đồng
                  </Row>
                  <div className="save-image box-img">
                    <Dragger {...propsUpdate}>
                      <p className="ant-upload-drag-icon">
                        <IconUploadFile />
                      </p>
                      <p className="ant-upload-text">
                        Chọn hoặc kéo ảnh <span style={{ color: '#85A5FF' }}> hoá đơn chuyển khoản</span> vào đây để tải ảnh lên
                      </p>
                    </Dragger>
                  </div>
                </Col>
              </div>
            </div>

            <FooterModal type={'submit'} handleLoading={checkBtnLoading} handleClickCancel={handleCancelModalCreate} labelCancel={'Hủy'} LabelSave={'Lưu nguồn vốn'}></FooterModal>
          </div>
        </Form>
      </GS.WrapperOverrideInput>
    </S.ModalCreate>
  );
}
